import { useQuery } from '@apollo/client'
import { TypedDocumentNode } from '@apollo/client'
import useCartId from '@shared/cart/useCartId'
import gql from 'graphql-tag'

import { getAuthorizationContext } from '@emico/login-token'
import { Unmaybe } from '@emico/utils'

import {
  AdyenPaymentMethodsQuery,
  AdyenPaymentMethodsQueryVariables,
} from './useAdyenPaymentMethods.generated'

const ADYEN_PAYMENT_METHODS = gql`
  query AdyenPaymentMethods($guestCartId: String!) {
    adyenPaymentMethods(cart_id: $guestCartId) {
      paymentMethodsResponse {
        paymentMethods {
          type
          name
          brands
          configuration {
            merchantId
          }
          details {
            type
            key
            optional
            items {
              id
              name
            }
          }
        }
      }
    }
  }
` as TypedDocumentNode<
  AdyenPaymentMethodsQuery,
  AdyenPaymentMethodsQueryVariables
>

export type AdyenPaymentMethod = {
  name: string
  type: string
  details?: Unmaybe<
    AdyenPaymentMethodsQuery,
    'adyenPaymentMethods',
    'paymentMethodsResponse',
    'paymentMethods',
    'details'
  >
  configuration?: Unmaybe<
    AdyenPaymentMethodsQuery,
    'adyenPaymentMethods',
    'paymentMethodsResponse',
    'paymentMethods',
    'configuration'
  >
} & Unmaybe<
  Unmaybe<
    AdyenPaymentMethodsQuery,
    'adyenPaymentMethods',
    'paymentMethodsResponse',
    'paymentMethods'
  >
>

const parseData = (data?: AdyenPaymentMethodsQuery): AdyenPaymentMethod[] => {
  if (!data) {
    return []
  }

  const { paymentMethods } =
    data.adyenPaymentMethods?.paymentMethodsResponse || {}

  const filteredData = paymentMethods?.filter(
    (paymentMethod): paymentMethod is AdyenPaymentMethod =>
      paymentMethod !== undefined && paymentMethod !== null,
  )

  if (!filteredData) {
    return []
  }

  return filteredData.map((data) => ({
    ...data,
    details: data.details ?? undefined,
    configuration: data.configuration ?? undefined,
  }))
}

export const useAdyenPaymentMethods = (useCache: boolean = true) => {
  const cartId = useCartId()

  const { data, refetch, ...rest } = useQuery(ADYEN_PAYMENT_METHODS, {
    query: ADYEN_PAYMENT_METHODS,
    context: getAuthorizationContext(),
    fetchPolicy: useCache ? 'cache-first' : 'cache-and-network',
    errorPolicy: 'all',
    variables: {
      guestCartId: cartId,
    },
  })

  return {
    data,
    adyenPaymentMethods: parseData(data),
    getAdyenPaymentMethods: refetch,
    ...rest,
  }
}
