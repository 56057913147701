import { defaultDataIdFromObject, IdGetter, StoreObject } from '@apollo/client'
import React, { ReactNode } from 'react'

import { ApolloConnector } from '@emico/apollo'
import { dataIdFromObject as cartDataIdFromObject } from '@emico/cart'
import { RootErrorBoundary } from '@emico/error-boundary'
import { EntityUrl } from '@emico/graphql-schema-types'
import { I18nLoader } from '@emico/i18n'
import lazy from '@emico/lazy/src/index'
import { RouterProvider } from '@emico/magento'
import { PrismicPreviewLoader } from '@emico/prismic'
import { useActiveStoreView } from '@emico/storeviews'
import { BreakpointsProvider } from '@emico/ui'

import AppStyles from './AppStyles'
import { ErrorMessageProvider } from './components/ErrorMessage'
import { FatalErrorPage } from './components/FatalErrorPage'
import { MagentoSessionProvider } from './components/MagentoSessionContext'
import { SuccessMessageProvider } from './components/SuccessMessage'
import { TrustedShopsScript } from './components/TrustedShopsScript'
import fragmentTypes from './shared/apollo/fragmentTypes.generated'
import { setLinkContext } from './utils/setLinkContext'
import useSessionKey from './utils/useSessionKey'

const Debugger =
  process.env.NODE_ENV !== 'production'
    ? lazy(
        'debugger',
        () =>
          import(
            /* webpackChunkName: "debugger" */ './features/debug/Debugger'
          ),
      )
    : null

const dataIdFromObject: IdGetter = (object) => {
  const cartDataId = cartDataIdFromObject(object)
  if (cartDataId !== undefined) {
    return cartDataId
  }

  switch (object.__typename) {
    case 'EntityUrl': {
      const entityUrl = object as unknown as EntityUrl
      return `${object.__typename}:${entityUrl.type}:${entityUrl.id}`
    }
    default:
      return defaultDataIdFromObject(object as Readonly<StoreObject>)
  }
}

const App = ({
  disableErrorEvents = false,
  isComponent = false,
  componentId,
  children,
}: {
  disableErrorEvents?: boolean
  isComponent?: boolean
  componentId?: string
  children: ReactNode
}) => {
  const activeStoreView = useActiveStoreView()
  const sessionKey = useSessionKey()

  return (
    <>
      {isComponent && componentId ? (
        <AppStyles id={componentId} />
      ) : (
        <AppStyles />
      )}
      <I18nLoader>
        <RootErrorBoundary
          fallback={disableErrorEvents ? <></> : <FatalErrorPage />}
          disableErrorEvents={disableErrorEvents}
        >
          <PrismicPreviewLoader />

          <RouterProvider>
            <ApolloConnector
              possibleTypes={fragmentTypes.possibleTypes}
              dataIdFromObject={dataIdFromObject}
              resolvers={[]}
              links={[setLinkContext(activeStoreView, sessionKey)]}
              storeView={activeStoreView.code}
            >
              <ErrorMessageProvider>
                <SuccessMessageProvider>
                  <BreakpointsProvider>
                    {!isComponent && (
                      <TrustedShopsScript activeStoreView={activeStoreView} />
                    )}
                    <MagentoSessionProvider>
                      <>
                        {children}
                        {Debugger ? <Debugger /> : null}
                      </>
                    </MagentoSessionProvider>
                  </BreakpointsProvider>
                </SuccessMessageProvider>
              </ErrorMessageProvider>
            </ApolloConnector>
          </RouterProvider>
        </RootErrorBoundary>
      </I18nLoader>
    </>
  )
}

export default App
