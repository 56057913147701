import { AdyenCheckout, ICore } from '@adyen/adyen-web'
import { useCart } from '@shared/cart/useCart'
import React, {
  createContext,
  ReactNode,
  useRef,
  useContext,
  useEffect,
} from 'react'

import { useActiveStoreView } from '@emico/storeviews'

export const ADYEN_HPP_CODE = 'adyen_hpp'
export const ADYEN_CC_CODE = 'adyen_cc'
export const ADYEN_PREFIX = 'adyen_'
export const ADYEN_HPP_UNSUPPORTED = ['boleto', 'wechatpay', 'ratepay']
export const ADYEN_PAYMENT_METHOD_MAP = [
  { adyen: 'scheme', magento: 'cc' },
  { adyen: 'boletobancario', magento: 'boleto' },
]
export const ADYEN_HPP_BRAND_CODE_SCHEME = 'scheme'
export const ADYEN_HPP_BRAND_CODE_BANCONTACT = 'bcmc'

const Context = createContext<ICore | null>(null)

interface AdyenCheckoutProviderProps {
  children: ReactNode
}

export const AdyenCheckoutProvider = ({
  children,
}: AdyenCheckoutProviderProps) => {
  const activeStoreView = useActiveStoreView()
  const { cart } = useCart()
  let locale = 'nl-NL'

  if (activeStoreView.language === 'de' || activeStoreView.language === 'at') {
    locale = 'de-DE'
  }

  const environment =
    process.env.REACT_APP_ADYEN_ENVIRONMENT ?? ('test' as 'test' | 'live')
  const adyenEnvironment: 'test' | 'live' = ['test', 'live'].includes(
    environment,
  )
    ? (environment as 'test' | 'live')
    : 'test'

  const countryCode = cart?.billingAddress?.country.code

  const checkout = useRef<ICore | null>(null)
  useEffect(() => {
    if (!countryCode) {
      return
    }
    AdyenCheckout({
      locale,
      countryCode: countryCode,
      environment: adyenEnvironment,
      clientKey: process.env.REACT_APP_ADYEN_CLIENT_KEY,
      showPayButton: false,
    }).then((adyenCheckout: ICore) => {
      checkout.current = adyenCheckout
    })
  }, [countryCode, adyenEnvironment, locale])

  return (
    <Context.Provider value={checkout.current}>{children}</Context.Provider>
  )
}

export const useAdyenCheckout = () => useContext(Context)
