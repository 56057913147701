import { useApolloClient } from '@apollo/client'
import styled from '@emotion/styled'
import { Trans, t } from '@lingui/macro'
import { useErrorMessage } from '@src/components/ErrorMessage'
import { useSuccessMessage } from '@src/components/SuccessMessage'
import { useCreateGenericCustomerInterests } from '@src/components/useCreateGenericCustomerInterests'
import { useGenericCustomer } from '@src/components/useGenericCustomer'
import React, { useState } from 'react'

import { useActiveStoreView } from '@emico/storeviews'
import { minWidth, maxWidth } from '@emico/styles'

import {
  EmailForm as GenericEmailForm,
  EmailFormValues,
} from '../../components/EmailForm'

const FormWrapper = styled.div`
  @media ${maxWidth('sm')} {
    padding: 0 20px;
  }
`

const Description = styled.p`
  @media ${minWidth('md')} {
    font-size: 16px;
  }
`

export const EmailForm = () => {
  const apolloClient = useApolloClient()
  const storeView = useActiveStoreView()
  const source = localStorage?.getItem('advisorSource')
  const [isLoading, setLoading] = useState<boolean>(false)
  const getCustomerByEmail = useGenericCustomer(apolloClient)
  const createGenericCustomerInterests = useCreateGenericCustomerInterests()
  const { setMessage: setErrorMessage } = useErrorMessage()
  const { setMessage: setSuccessMessage } = useSuccessMessage()

  const errorMessage = t({
    id: 'contactPreferences.emailForm.emailCouldNotBeSent',
    message: 'There was a problem with sending the email.',
  })
  const successMessage = t({
    id: 'contactPreferences.emailForm.emailSent',
    message: 'Email has been sent',
  })

  const handleSubmit = async (values: EmailFormValues) => {
    setLoading(true)

    try {
      const customer = await getCustomerByEmail(values.email)

      if (customer) {
        let language = storeView.language

        if (storeView.language === 'be') {
          language = 'nl'
        } else if (storeView.language === 'at') {
          language = 'de'
        }

        await createGenericCustomerInterests({
          source: {
            type: source ? 'store' : 'website',
            name: source
              ? source
              : `Website ${storeView.language.toUpperCase()}`,
            campaign: 'contact-preference-link',
          },
          email: customer.email,
          language,
          addresses: [
            {
              countryCode: storeView.language.toUpperCase(),
            },
          ],
          interestData: [
            {
              key: 'Domain',
              value: storeView.language.toUpperCase(),
            },
          ],
        })
      }

      setLoading(false)
      setSuccessMessage(successMessage)
    } catch (e) {
      setLoading(false)
      if (e instanceof Error) {
        setErrorMessage(e.message || errorMessage)
      } else {
        setErrorMessage(errorMessage)
      }
    }
  }

  return (
    <FormWrapper>
      <Description>
        <Trans id="contactPreferences.emailForm.description">
          Uitleg over de te maken keuze
        </Trans>
      </Description>
      <GenericEmailForm
        onSubmit={handleSubmit}
        emailLabel={t({
          id: 'contactPreferences.emailForm.emailInputLabel',
          message: 'E-mailadres',
        })}
        submitLabel={
          <Trans id="contactPreferences.emailForm.submitLabel">
            Voorkeuren opslaan
          </Trans>
        }
        loading={isLoading}
      />
    </FormWrapper>
  )
}
